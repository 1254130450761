<template>
  <div id="recordView" class="page">
    <SearchHero />

    <ActiveRefinements class="is-hidden" />

    <RecordMap :record="record" />

    <section id="profile" class="columns is-centered">
      <div class="column is-10 profile-shadow">
        <div class="columns is-centered is-multiline">
          <BackButton class="column is-narrow is-hidden-mobile" />
          <div class="column columns is-multiline">
            <div
              id="image-profile-section"
              class="column is-5 profile-section"
              :style="record['image-uri'] ? '' : 'order: 2'"
            >
              <div v-if="record['image-uri']" id="profile-image" class="has-text-centered">
                <img :src="record['image-uri']" :alt="record.name">
                <SponsoredBadge v-if="record.badge" />
                <ExpertBadge :expert="record.expert" />
              </div>

              <h1 class="title is-hidden-desktop is-hidden-tablet-only" v-html="record.name" />

              <PrimaryFilterText :record="record" />

              <AddressBlock :record="record" />
              <RankBlock :record="record" />
            </div>

            <div
              id="name-profile-section"
              class="column profile-section"
              :style="record['image-uri'] ? '' : 'order: 1'"
            >
              <ExpertBadge v-if="!record['image-uri']" :expert="record.expert" />
              <h1 class="title is-hidden-mobile" v-html="record.name" />

              <RecordFacets :record="record" />
              <RecordFields :record="record" />

              <DirectoryLinks :record="record" />

              <Footnotes :record="record" />

              <p class="has-text-weight-semibold mt-6">
                Let {{ record.name }} know you found them on the {{ instanceName }} directory!
              </p>
            </div>
          </div>

          <div
            class="column is-narrow has-text-centered profile-section"
            :style="record['image-uri'] ? '' : 'order: 3'"
          >
            <div class="floated-buttons buttons is-right is-hidden-touch">
              <ExpertButton v-if="!record.expert" />
              <Sharing />
            </div>

            <Ad ad-slot="RecordAd" />
          </div>
        </div>
        <Corrections :name="record.name" />
      </div>
    </section>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { mapGetters, mapState } from 'vuex';
import SearchHero from '@/components/Global/SearchHero.vue';
import Sharing from '@/components/Global/Sharing.vue';
import Corrections from '@/components/Record/Corrections.vue';
import ExpertButton from '@/components/Record/ExpertButton.vue';
import SponsoredBadge from '@/components/Record/SponsoredBadge.vue';
import ExpertBadge from '@/components/Badges/Expert.vue';
import AddressBlock from '@/components/Record/AddressBlock.vue';
import RankBlock from '@/components/Record/RankBlock.vue';
import DirectoryLinks from '@/components/Record/DirectoryLinks.vue';
import PrimaryFilterText from '@/components/Record/PrimaryFilterText.vue';
import BackButton from '@/components/Record/BackButton.vue';
import RecordMap from '@/components/Record/RecordMap.vue';
import Footnotes from '@/components/Record/Footnotes.vue';
import RecordFacets from '@/components/Record/RecordFacets.vue';
import RecordFields from '@/components/Record/RecordFields.vue';
import ActiveRefinements from '@/components/InstantSearch/ActiveRefinements.vue';
import store from '@/store';

export default {
  data: () => ({
    record: {
      awards: {
        lvl0: [],
      },
      location: {
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        position: {},
      },
    },
    searchClient: algoliasearch(
      store.state.algolia_app_id,
      store.state.algolia_search_api_key,
    ),
  }),
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      index: 'directory/index',
    }),
    ...mapState({
      metaDescription: (state) => state.meta_description,
      instanceName: (state) => state.name,
    }),
  },
  methods: {
    initAlgolia() {
      // Set up the index, which we later use to search for the record
      const index = this.searchClient.initIndex(this.index);

      // Search for the record via Algolia
      // If it exists, use the first result for the view, otherwise return a 404
      // Remove `and-` because our slugify and Algolia handle ampersands weird
      index.search(this.$route.params.record.replace('-and-', '%'))
        .then(({ hits }) => {
          if (!hits || hits.length === 0) {
            this.$router.push('/404');
          }

          [this.record] = hits;
        });
    },
  },
  mounted() {
    this.$store.dispatch('advertisements/get');
    this.initAlgolia();
  },
  components: {
    SearchHero,
    Sharing,
    Corrections,
    ExpertButton,
    SponsoredBadge,
    ExpertBadge,
    AddressBlock,
    RankBlock,
    DirectoryLinks,
    PrimaryFilterText,
    BackButton,
    RecordMap,
    RecordFacets,
    RecordFields,
    ActiveRefinements,
    Footnotes,
    Ad: () => import('@/components/Ads/Ad.vue'),
  },
  metaInfo() {
    const recordName = this.record?.name;
    const { instanceName } = this;
    const recordBio = this.record?.bio ?? this.metaDescription;
    const title = `${recordName} - ${instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: `${recordBio}`,
      },
    ];
    return { title, meta };
  },
};
</script>
