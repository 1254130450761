<template>
  <div>
    <p v-for="year in annualFields(field.recordValue)" :key="year">
      <span class="has-text-weight-bold">{{ year }}:</span>
      <span class="pl-1" v-html="formatField(field.type, field.recordValue[year])" />
      <sup v-if="footnote(year)" class="is-superscript">
        {{ footnote(year).index }}
      </sup>
    </p>
  </div>
</template>

<script>
import formatField from '@/mixins/fields/formatField';
import footnotes from '@/mixins/footnotes';

export default {
  mixins: [
    formatField,
    footnotes,
  ],
  props: {
    record: Object,
    field: Object,
  },
  methods: {
    footnote(year) {
      return this.getFootnote(this.field.key, year);
    },
    annualFields(fields) {
      return Object.keys(fields).sort((a, b) => (b - a));
    },
  },
};
</script>
