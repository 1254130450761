<template>
  <div v-if="hasFootnotes" class="table is-narrow has-footnotes">
    <h6 class="heading is-size-6 mb-1">
      FOOTNOTES
    </h6>
    <tr
      v-for="footnote in footnotes"
      :key="`${footnote.fieldKey}${footnote.year}`"
    >
      <td class="has-text-weight-semibold has-text-centered py-0">
        {{ footnote.index }}
      </td>
      <td class="py-0">
        {{ footnote.footnote }}
      </td>
    </tr>
  </div>
</template>

<script>
import footnotes from '@/mixins/footnotes';

export default {
  mixins: [footnotes],
  props: {
    record: Object,
  },
};
</script>
