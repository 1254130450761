<template>
  <a class="subtitle is-size-6" :href="field.value" v-html="field.recordValue" />
</template>

<script>
export default {
  props: {
    field: Object,
  },
};
</script>
