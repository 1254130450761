<template>
  <section class="hero is-medium is-paddingless is-record-map-container">
    <div v-if="hasLocation" class="hero is-medium" :class="{ 'is-record-map': hasLocation }">
      <l-map ref="map" :zoom="16" :center="position" :options="{ zoomControl: false }">
        <l-tile-layer ref="tileLayer" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <l-marker :lat-lng="position" />
      </l-map>
    </div>
    <div v-else class="no-map-background" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      map: (state) => state.map,
    }),
    hasLocation() {
      return this.map.enabled
        && this.record?.locations?.length > 0
        && this.position.lat !== null
        && this.position.lng !== null;
    },
    position() {
      const firstPosition = this.record.locations[0]?.position;
      if (firstPosition && firstPosition.lat && firstPosition.lng) {
        return {
          lat: parseFloat(firstPosition.lat),
          lng: parseFloat(firstPosition.lng),
        };
      }

      return {
        lat: null,
        lng: null,
      };
    },
  },
};
</script>
