/* eslint-disable no-param-reassign */

export default {
  computed: {
    hasFootnotes() {
      return Object.keys(this.rawFootnotes).length > 0;
    },
    rawFootnotes() {
      return this.record?.footnotes ?? {};
    },
    footnotes() {
      return Object.keys(this.rawFootnotes).reduce((carry, footnoteKey) => {
        const footnote = this.rawFootnotes[footnoteKey];
        const fieldKey = footnoteKey.split('_')[0];
        const year = footnoteKey.split('_')[1];

        carry.push({
          index: carry.length + 1,
          fieldKey,
          year,
          footnote,
        });

        return carry;
      }, []);
    },
  },
  methods: {
    getFootnote(fieldKey, year = undefined) {
      return this.footnotes
        .find((footnote) => footnote.fieldKey === fieldKey && footnote.year === year);
    },
  },
};
