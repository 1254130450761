<template>
  <div class="pl-4">
    <div v-if="hasLocations" class="address">
      <div class="columns is-mobile">
        <div class="column is-narrow pr-1">
          <span class="icon"><fa icon="map-marker" full-width /></span>
        </div>
        <div class="column pl-1">
          <p class="has-text-weight-semibold">
            {{ location.name }}
          </p>
          <p>{{ location.address }}</p>
          <p>
            <span v-if="location.city">{{ location.city }},</span>
            {{ location.state }}
            {{ location.zip }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="(location && location.phone) || record.phone" class="is-together">
      <span class="icon"><fa icon="phone" full-width /></span>
      <a :href="`tel:${location.phone || record.phone}`">
        {{ location.phone || record.phone }}
      </a>
    </div>
    <div v-if="record['web-profile']" class="is-together">
      <span class="icon"><fa icon="globe" full-width /></span>
      <a :href="format('url', record['web-profile'])" target="_blank">
        {{ record['web-profile'] }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';

export default {
  mixins: [formatField],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/get',
    }),
    hasLocations() {
      return this.record?.locations?.[0]?.address;
    },
    location() {
      return this.record?.locations?.[0];
    },
  },
  methods: {
    mapFields(field) {
      return [
        {
          value: this.formatField(field.type, this.record[field.key]),
        },
      ];
    },
    format(type, field) {
      return this.formatField(type, field);
    },
  },
};
</script>
