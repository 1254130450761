<template>
  <a class="subtitle is-size-6" :href="`mailto:${field.value}`">
    <span class="is-underlined" v-html="field.value" />
  </a>
</template>

<script>
export default {
  props: {
    field: Object,
  },
};
</script>
