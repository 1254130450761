<template>
  <router-link
    v-if="isExpertEnabled"
    :to="{ name: 'expert' }"
    class="button is-info"
  >
    <!--TODO: Replace with a label and link setting-->
    Become a Preferred Partner
  </router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isExpertEnabled: (state) => state.expert?.enabled,
    }),
  },
};
</script>
