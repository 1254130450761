<template>
  <div>
    <p v-for="value in lvl0" :key="value">
      {{ value }}
      (<span v-for="(year, index) in filteredTreeFacet(value)" :key="year">
        <span>{{ year.split(' > ')[1] }}</span>
        <span v-if="index !== (filteredTreeFacet(value).length - 1)">, </span>
      </span>)
    </p>
  </div>
</template>

<script>
export default {
  props: {
    facet: Object,
    record: Object,
  },
  computed: {
    lvl0() {
      return this.record[this.facet.key].lvl0;
    },
  },
  methods: {
    filteredTreeFacet(category) {
      // Gets all the values where the category is the same as the nested tree value
      // e.g. "Sports Medicine > 2018" grabs all instances where category is "Sports Medicine"
      return this.record[this.facet.key].lvl1
        .filter((item) => item.split(' > ')[0] === category);
    },
  },
};
</script>
