<template>
  <a class="button floated-buttons is-dark is-back-button" @click.prevent="back">
    <fa icon="arrow-left" is-fullwidth size="sm" />
  </a>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
